<template>
  <b-custom-tabs :tabs="tabs" lazy />
</template>

<script>
import pendingList from "./pendingList.vue";
import confirmHistory from "./confirmHistory.vue";

export default {
  data() {
    return {
      tabs: [
        { title: "Onay Bekleyenler", component: pendingList },
        { title: "Onay Geçmişi", component: confirmHistory },
      ],
    };
  },
};
</script>

<style></style>
