<template>
  <app-overlay>
    <searchable-table
      class="border mb-50"
      :items="items"
      :fields="fields"
      hover
      filterable
      @row-clicked="showDetails"
    >
      <template #filtercollapse />
      <template #actions="{ item }">
        <div class="d-flex gap-5">
          <app-icon-button
            icon="CheckIcon"
            variant="gradient-success"
            @click="updateConfirm(item, 'accept')"
          />
          <app-icon-button
            icon="XIcon"
            variant="gradient-danger"
            @click="updateConfirm(item, 'reject')"
          />
        </div>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import newEmployeeDetail from './newEmployeeDetail.vue'
import newBranchDetail from './newBranchDetail.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'sender.fullname', label: 'Onay İsteyen' },
        { key: 'type.label', label: 'Talep' },
        { key: 'createdAt', label: 'Oluşturulma Tarihi', formatDateTime: true },
        { key: 'actions', label: 'Eylemler' },
      ],
    }
  },
  computed: {
    items() {
      return this.$store.getters.pendingConfirms
    },
  },
  mounted() {
    this.$store.dispatch('getPendingConfirms')
  },
  methods: {
    showDetails(item) {
      switch (item?.type?.action) {
        case 'newEmployee':
          this.$showAppModal({
            title: 'Yeni Çalışan Detayı',
            component: newEmployeeDetail,
            item: item?.data,
          })
          break
        case 'newBranch':
          this.$showAppModal({
            title: 'Yeni Sicil Detayı',
            component: newBranchDetail,
            item: item?.data,
          })
          break
        default:
          break
      }
    },
    updateConfirm(item, type) {
      this.$confirm({ message: this.message(type) }, () => this.$store.dispatch('updateConfirm', { id: item?._id, type }))
    },
    message(type) {
      return type === 'accept' ? 'Onay verilecek emin misiniz?' : 'Reddedilecek emin misiniz?'
    },
  },
}
</script>

<style></style>
