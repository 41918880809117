<template>
  <app-overlay>
    <searchable-table
      class="border mb-50"
      :items="items"
      :fields="fields"
      hover
      @row-clicked="showDetails"
    >
      <template #status="{ item }">
        <b-badge :variant="item.status === 'reddedildi' ? 'danger' : 'success'">
          {{ $capitalize(item.status) }}
        </b-badge>
      </template>
    </searchable-table>
  </app-overlay>
</template>

<script>
import newEmployeeDetail from './newEmployeeDetail.vue'
import newBranchDetail from './newBranchDetail.vue'

export default {
  data() {
    return {
      fields: [
        { key: 'sender.fullname', label: 'Onay İsteyen' },
        { key: 'type.label', label: 'Talep' },
        { key: 'status', label: 'Onay Durumu' },
        { key: 'createdAt', label: 'Oluşturulma Tarihi', formatDateTime: true },
        { key: 'updatedAt', label: 'Onay Tarihi', formatDateTime: true },
      ],
    }
  },
  computed: {
    items() {
      return this.$store.getters.confirmHistory
    },
  },
  mounted() {
    this.$store.dispatch('getConfirmHistory')
  },
  methods: {
    showDetails(item) {
      switch (item?.type?.action) {
        case 'newEmployee':
          this.$showAppModal({ title: 'Yeni Çalışan Detayı', component: newEmployeeDetail, item: item?.data })
          break
        case 'newBranch':
          this.$showAppModal({ title: 'Yeni Sicil Detayı', component: newBranchDetail, item: item?.data })
          break
        default:
          break
      }
    },
  },
}
</script>

<style></style>
