var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('GmapMap',{ref:"mapRef",staticStyle:{"width":"100%","height":"30vh"},attrs:{"id":"map","options":{
    zoomControl: true,
    fullscreenControl: true,
    clickableIcons: true,
    mapTypeControl: false,
    scaleControl: true,
    streetViewControl: false,
    rotateControl: true,
    disableDefaultUi: false,
  },"center":_vm.center,"zoom":_vm.zoom}},[_c('GmapMarker',{attrs:{"position":_vm.position}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }