<template>
  <div>
    <app-detail-table
      :data="item"
      :fields="fields"
    >
      <template #address="data">
        {{ $capitalize(showAddress(data)) }}
      </template>
    </app-detail-table>
    <map-info
      v-if="checkLocationExits(item)"
      :position="checkLocationExits(item)"
    />
  </div>
</template>

<script>
import mapInfo from './mapInfo.vue'

export default {
  components: { mapInfo },
  data() {
    return {
      fields: [
        { key: 'company.name', label: 'Firma' },
        { key: 'name', label: 'Sicil Adı' },
        { key: 'recordNo', label: 'Sicil No' },
        { key: 'riskState.value', label: 'Tehlike Sınıfı' },
        { key: 'address', label: 'Adres' },
      ],
    }
  },
  computed: {
    item() {
      return this.$store.getters?.appModalItem
    },
    showAddress() {
      return data => {
        const address = Object.values(data?.address).filter(item => item?.value)
        return address.length > 0 ? address.map(item => item?.value).join(', ') : '-'
      }
    },
    checkLocationExits() {
      return data => {
        if (data?.address?.location?.lat && data?.address?.location?.lng) return { ...data?.address?.location }
        return false
      }
    },
  },
}
</script>

<style></style>
