<template>
  <GmapMap
    id="map"
    ref="mapRef"
    :options="{
      zoomControl: true,
      fullscreenControl: true,
      clickableIcons: true,
      mapTypeControl: false,
      scaleControl: true,
      streetViewControl: false,
      rotateControl: true,
      disableDefaultUi: false,
    }"
    :center="center"
    :zoom="zoom"
    style="width: 100%; height: 30vh"
  >
    <GmapMarker :position="position" />
  </GmapMap>
</template>

<script>
export default {
  props: {
    position: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      center: { lat: 38.963745, lng: 35.243322 },
      zoom: 6.6,
    }
  },
  mounted() {
    if (this.position.lat && this.position.lng) this.setLocation(this.position)
  },
  methods: {
    setLocation(loc) {
      this.$refs.mapRef.$mapPromise.then(map => map.panTo(loc))
      this.zoom = 16
    },
  },
}
</script>

<style></style>
