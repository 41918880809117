<template>
  <app-detail-table
    :data="items"
    :fields="fields"
  />
</template>

<script>
export default {
  data() {
    return {
      fields: [
        { key: 'fullname', label: 'Ad Soyad' },
        { key: 'tcNo', label: 'TC Kimlik' },
        { key: 'company.name', label: 'Firma' },
        { key: 'branch.name', label: 'Sicil' },
        { key: 'phone', label: 'Telefon' },
        { key: 'email', label: 'E-Posta' },
      ],
    }
  },
  computed: {
    items() {
      return this.$store.getters?.appModalItem
    },
  },
}
</script>

<style></style>
